<template>
  <main class="box_main">
    <el-row :gutter="30">
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <div class="box">
          <h2>{{ dataTimeDays }}</h2>
          <h4>DAYS</h4>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <div class="box">
          <h2>{{ dataTimeHours }}</h2>
          <h4>HOURS</h4>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <div class="box">
          <h2>{{ dataTimeMinutes }}</h2>
          <h4>MINUTES</h4>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <div class="box">
          <h2>{{ dataTimeSeconds }}</h2>
          <h4>SECONDS</h4>
        </div>
      </el-col>
    </el-row>
  </main>
</template>

<script>
import Moment from 'moment'
export default {
  name: 'Home',
  data() {
    return {
      dataTimeDays: new Moment().format('DD'),
      dataTimeHours: new Moment().format('HH'),
      dataTimeMinutes: new Moment().format('mm'),
      dataTimeSeconds: new Moment().format('ss')
    }
  },
  created() {
    this.runTime()
  },
  methods: {
    runTime() {
      const that = this
      setInterval(function() {
        that.dataTimeDay = new Moment().format('DD')
        that.dataTimeHours = new Moment().format('HH')
        that.dataTimeMinutes = new Moment().format('mm')
        that.dataTimeSeconds = new Moment().format('ss')
      }, 1000)
    }
  }
}
</script>
<style lang="less">
.box_main {
  .box {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
  }
  .box:before {
    content: '';
    position: absolute;
    width: 50%;
    height: 140%;
    background: linear-gradient(315deg, #00ccff, #d400d4);
    animation: animate 4s linear infinite;
  }
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .box:after {
    content: '';
    position: absolute;
    inset: 4px;
    background: #0e1538;
    border-radius: 15px;
    z-index: 2;
  }
  h2 {
    position: relative;
    color: #fff;
    z-index: 4;
    font-size: 4em;
  }
  h4 {
    position: relative;
    color: #fff;
    z-index: 4;
  }
}
</style>
